<template>
  <div class="p404-container">
    <div class="page-404">
      <img src="@/assets/img/404.png">
      <p class="notice">抱歉！你访问的页面不存在！</p>
      <router-link to="/base/home" class="redirect">{{seconds}} 秒后自动跳转到首页，或点击直接跳转</router-link>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
	data() {
		return {
			seconds: 6,
			timer: '',
		};
	},
	created() {
		this.go();
	},
	methods: {
		go() {
			if (--this.seconds > 0) {
				this.timer = setTimeout(() => {
					this.go();
				}, 1000);
			} else {
				this.$router.push('/base/home');
			}
		},
	},
	destroyed() {
		clearTimeout(this.timer);
	},
};
</script>
<style>
.p404-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  text-align: center;
}

.p404-container .page-404 {
  position: absolute;
  top: 20%;
  left: 50%;
  margin-left: -250px;
}

.p404-container .notice {
  line-height: 28px;
}

.p404-container .redirect {
  color: #fdad00;
  font-size: 18px;
  line-height: 30px;
}

.p404-container .redirect:hover {
  text-decoration: underline;
}
</style>
